.download-hero-banner{
    background: url(../../Assests/images/download-banner.png);
    background-position: 100% 100%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0;
}
.download-hero-banner > .container> .row{
    align-items: center;
}
.download-hero-banner h1{
    font-size: 2.5rem;
    color: #E6013B;
    font-weight: 700;
    line-height: 2.8rem;
    text-align: left;
}
.download-hero-banner h5{
    font-size: 1.2rem;
    color: #888;
    line-height: 1.5rem;
    margin: 0;
    text-align: left;
}
.download-inner{
    display: flex;
}
.download-inner ul.nav.nav-tabs{
    width: 200px;
    display: block;
    border-bottom: none;
}
.download-inner ul li,
.download-inner ul li button{
    display: block;
    width: 100%;
    text-align: left;
    color: #878787;
    font-weight: 600;
}
.download-inner ul li button.nav-link{
    margin-bottom: 0;
    font-size: 1rem;
}
.download-inner ul li button.nav-link:hover{
    border-color: transparent;
}
.download-inner ul li button.nav-link.active{
    background-color: #feeeef;
    color: #000;
    border-color: transparent;
}
/* .download-inner ul li button{
    background-color: #feeeef;
} */
.download-inner .tab-content {
    width: calc(100% - 200px);
    padding: 15px;
    box-shadow: 0 0 10px #f0f0f0;
    border-radius: 20px;
    margin-left: 20px;
    margin-bottom: 2rem;
  }
.download-inner .tab-content .table thead th{
    background: #f3f3f3;
    border: none !important;
    color: #000;
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
}
.download-inner .tab-content .table tbody td{
    border: none;
    border-bottom: 1px solid #dee2e6;
    color: #000;
    font-size: .9rem;
    text-align: left;
}
/* .download-inner .tab-content .table tbody tr:nth-of-type(odd),.download-inner .tab-content .table tbody tr:hover{
    background-color: #f8f8ff;
} */
.download-inner .tab-content .table tbody tr:hover{
    background-color: #f8f8ff;
}
.download-table-view{
    padding: 0;
}
.download-table-view h3{
    font-size: 2.3rem;
    font-weight: 800;
    padding: 2rem 0;
    text-transform: uppercase;
}
.download-table-view h3>span{
    border-bottom: 2px solid #211f72;
    padding-left: 1.25rem;
}
@media(min-width:576px) and (max-width:767.90px){
    .download-hero-banner h1{
        text-align: center;
    }
    .download-hero-banner h5{
        text-align: center;
        font-size: 1.25rem;
    }
    .download-inner{
        display: block;
    }
    .download-inner .tab-content{
        width: 100%;
    }
    .download-inner ul li, .download-inner ul li button{
        display: inline-block;
        width: auto;
    }
    .download-inner ul.nav.nav-tabs{
        width: 100%;
    }
    .download-inner ul li button.nav-link{
        padding: 0.5rem;
    }
    
}
@media(min-width:320px) and (max-width:575.90px){
    .download-hero-banner h1,
    .download-table-view h3{
        text-align: center;
        font-size: 1.5rem;
    }
    .download-hero-banner h5{
        text-align: center;
        font-size: 1.25rem;
    }
    .download-hero-banner{
        padding: 1rem 0;
    }
    .download-inner{
        display: block;
    }
    .download-inner .tab-content{
        width: 100%;
    }
    .download-inner ul li, .download-inner ul li button{
        display: inline-block;
        width: auto;
    }
    .download-inner ul.nav.nav-tabs{
        width: 100%;
    }
}