.AboutMain-heading{
    font-size: 40px; font-weight: bold; color: #000;
    text-align: left; line-height: 2.7rem;  padding: 2rem 2rem 1rem;
}
.AboutMain-heading span{display: block;}
.About-Main-discription{font-size: 1rem;
    text-align: left;
    padding: 1rem 4rem 1rem 2rem;}
.About-CTA button{
    background: #211F72; font-size: 1rem; color: #fff;border: 0;
border-radius: 2rem; padding: .3rem 2rem .4rem; float: left; margin: .5rem 2rem;
}
.aboutseconetoppadd{ margin: 2rem 0 3rem 0; }
@media (max-width : 991.50px){
    .AboutMain-heading{padding: 0; font-size: 2.1rem;}
    .About-Main-discription{padding: 0; line-height: 1.8rem;}
    .About-CTA button {margin: .5rem 0rem;}
}
@media (max-width: 767.50px) {
    .AboutMain-heading {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
      .About-Main-discription{
        line-height: 1.5rem;
      }
    
}
@media (max-width : 575.50px){
    .AboutMain-heading{text-align: center;}
    .AboutMain-heading span{display: inline;}
    .About-Main-discription { text-align: center;}
    .About-CTA{width: 100%; display: block; margin: 1rem 0; display: flex;}
    .About-CTA button{display: block; margin: 0 auto;}
    
}
@media (max-width: 359.5px){
.AboutMain-heading {
  font-size: 1.5rem;
  line-height: 2rem;
}
}