.ProductServicesMainBanner{
    background-image: linear-gradient(to right, #FBFBFB , #FFF8E8);
    padding: 2rem 0;
}
.ProSerBannerTeaxt{
    font-size: 1.2rem; color: #888; line-height: 1.5rem;
    margin: 0; text-align: left; padding-top: 15%; padding-right: 4rem;
}
.ProSerBannerTeaxt span{
    font-size: 2.5rem; color: #E6013B; font-weight: 700; line-height: 2.8rem;
}
.PorductHeading{font-size: 2.3rem; font-weight: 800; padding: 2rem 0;}
@media(min-width:768px) and (max-width:991px){
    .ProSerBannerTeaxt{font-size: 1.1rem; padding-top: 8%; }
    .ProSerBannerTeaxt span {
        font-size: 2rem; line-height: 1.6rem;}
}

@media(min-width:567px) and (max-width:767.50px){
    .ProSerBannerTeaxt{font-size: 1.1rem; padding-top: 8%; }
    .ProSerBannerTeaxt span {
        font-size: 2rem; line-height: 1.6rem;}
        .ProSerBannerTeaxt{text-align: center; padding-top: 0%;}
        .ProductServicesMainBanner{padding: .5rem 0;}
}

@media(min-width:480px) and (max-width:575.50px){
    .ProSerBannerTeaxt{font-size: 1.1rem; padding-top: 0%; padding-right: 0rem; }
    .ProSerBannerTeaxt span {
        font-size: 1.8rem; line-height: 1.6rem;}
        .ProSerBannerTeaxt{text-align: center; padding-top: 0%;}
        .ProductServicesMainBanner{padding: .5rem 0;}
}
@media(min-width:300px) and (max-width:479.50px){
    .ProSerBannerTeaxt{font-size: 1.1rem; padding-top: 0%; padding-right: 0rem; }
    .ProSerBannerTeaxt span {
        font-size: 1.7rem; line-height: 1.6rem;}
        .ProSerBannerTeaxt{text-align: center; padding-top: 0%;}
        .ProductServicesMainBanner{padding: .5rem 0;}
}