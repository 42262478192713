.complaint-modal.risk-modal ul {
    list-style: disc;
    padding-left: 17px;
}
.complaint-modal.risk-modal ul li{
    line-height: 1.2rem;
}
.complaint-modal.risk-modal .modal-dialog {
    max-width: 760px;
}
.complaint-modal.risk-modal ul li a{
    color: #0056b3;
}