
.AboutsecundSecText{background: #211F72;
margin-left: -14rem; border: .5rem #fff solid;
margin-top: 14%;}
.AboutsecundSecText ul {display: block;}
.AboutsecundSecText ul li{ text-align: left; display: block; color: #fff; font-size: 1rem;
padding: .2rem 1rem; }
.AboutsecundSecText ul li:first-child{ font-size: 1.6rem; font-weight: 900; padding-left: 0;}
.AboutsecundSecText ul li:nth-child(2):before, .AboutsecundSecText ul li:nth-child(3):before  {content: ''; 
    width: 9px; height: 9px;
background-color: #fff; position: absolute; margin: .6rem 0 0 -1rem; border-radius: 100%;}

@media (max-width : 767.50px) {
    .AboutsecundSecText{ margin-left: 5%;  margin-top: -7.3rem; width: 90%; }
    
}
@media (max-width: 757.5px){
.AboutsecundSecText { margin-top: -9.3rem; }
}
@media (max-width : 575.50px){
    .AboutsecundSecText ul li:first-child {
        font-size: 1.4rem; }
}
@media (max-width : 359.50px){
    .AboutsecundSecText ul li{
        padding: .2rem 0rem;
    }
}