.static-heading h2{
    color: #000;
    font-size: 40px;
    font-weight: 700;
    line-height: 2.7rem;
    text-align: left;
    margin-bottom: 1rem;
}
.inactive-sc{
    padding: 2rem 0;
    margin-bottom: 2rem;
}
.static-pg p{
    display: block;
    font-size: 0.95rem;
    text-align: left;
}
.static-pg p:last-child{
    margin-bottom: 0;
}
.static-bg{
    background: linear-gradient(180deg,#f7f8fa 0,#e2f1f9);
}


@media(min-width:768px) and (max-width:991.90px){
    .static-heading h2{
        font-size: 36px;
    }
    .static-pg p{
        font-size: 0.9rem;
    }
}

@media(min-width:481px) and (max-width:767.90px){
    .static-heading h2{
        font-size: 32px;
    }
    .static-pg p{
        font-size: 0.85rem;
        text-align:justify;
    }
}

@media(min-width:320px) and (max-width:480.90px){
    .static-heading h2{
        font-size: 30px;
    }
    .static-pg p{
        font-size: 0.8rem;
        text-align:justify;
    }
}
