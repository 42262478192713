.AllServicesBox{
    font-size: 1rem;
color: #000;
line-height: 1.6rem;
text-align: left;
padding: 1.2rem;
box-shadow: 0px 0px 8px #e8e8e8;
border-radius: .7rem;
margin-top: 2rem;
}
.AllServicesBox span{
    font-size: 1.3rem;
    color: #211E72;
    font-weight: 700;
    width: 100%;
    display: block;
}

@media(min-width:768px) and (max-width: 991.50px){
    .AllServicesBox{padding-top: 0; margin-top: 0;}
}
@media(min-width:576px) and (max-width: 767.50px){
    .AllServicesBox{padding-top: 0; margin-top: 0;}
}
@media(min-width:480px) and (max-width: 575.50px){
    .AllServicesBox{padding-top: 0; margin-top: 0;}
    .PorductHeading {font-size: 2rem;}
}
@media(min-width:300px)  and (max-width: 479.50px){
    .AllServicesBox{padding-top: 0; margin-top: 0; font-size: .9rem;}
    .AllServicesBox span{font-size: 1rem;}
    .PorductHeading {
        font-size: 1.5rem;}
}