.about{
    padding: 6rem 0;
    background-image: url(../../../Assests/images/about.jpg);
    background-size: 100% 100%;
    /* filter: blur(8px);
  -webkit-filter: blur(8px); */
}
.about h2{
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 1.5rem;
    font-weight: bold;
}
.about h5{
    font-size: 1rem;
    color: var(--color-white);
    line-height: 1.75rem;
    font-weight: normal;
}
.about p{
    margin-top: 25px;
    color: var(--color-white);
    line-height: 1.5rem;
    font-weight: normal;
    font-size: 0.9rem;
}
@media(min-width:992px) and (max-width:1024px){
    .about {
        padding: 2rem 0;
    }
    .about h2{
        font-size: 2rem;
    }
}
@media(min-width:768px) and (max-width:991px){
    .about h2{
        font-size: 1.65rem;
    }
}
@media(min-width:576px) and (max-width:767px){
    .about h2{font-size: 1.6rem;}
}
@media(min-width:320px) and (max-width:575px){
    .about h2{font-size: 1.6rem;}
    .about {
        padding: 2rem 0;
    }
    .about h5{
        font-size: 0.9rem;
    }
}