.navbar{
    background: var(--color-white);
}
.navbar-collapse{
    display: flex;
    justify-content: space-between;
}
.navbar-nav{
    width: 100%;
    justify-content: end;
}
.navbar-nav a{
    font-size: 1rem;
    color: var(--color-black) !important;
    font-weight: 600;

}
.navbar-nav a.nav-link.active{
    background-color: var(--background-color);
    color: var(--color-white) !important;
}
.navbar-nav a.open-account-btn{
    background-color: #9796bf;
    color: var(--color-white) !important;
    border-radius: 1rem;
    font-size: 0.8rem;
    transition: var(--trannsition);
}
.navbar-nav a.open-account-btn:hover{
    background-color: var(--background-color);
}
.menubody {align-items: center;
    justify-content: end;}
.menubody .nav-item a {padding: .5rem .7rem;  margin: 0; display: inline-flex; font-weight: 600;
color: #000; font-size: 1rem; }
.menubody .nav-item a.demat-acc {border: 0; background-color: #6460ff; color: #fff;
    padding: .2rem 1rem .3rem; font-size: .9rem; border-radius: 1rem;line-height: 22px;}
    .MobileMenuShow{display: none; padding-top: .2rem;}
    .HumberIcon{background-image: url(./../../Assests/images/bhaijeeHumbergerIcon.png);
        background-repeat: no-repeat; background-position: .8rem .5rem;}
        .HumberIcon,.dropdown,.dropdown-toggle::after{border-top: .0em solid;
            border-right: .0em solid transparent !important;
            border-bottom: 0;
            border-left: .0em solid transparent !important;}


/* .MobileNavMenu{
    width: 300px;
    background: #6d6ad1;
    position: fixed;
    top: 100px;
    right: 0px;
    transition: var(--trannsition);
    overflow: hidden;
}
.showOnMobile{
    display: none;
    right: -300px;
    width: 300px;
    position: fixed;
    transition: var(--trannsition);
    background-color: #6d6ad1;
    animation-delay: 2s;
}
.showOnMobile.Active{
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    transition: var(--trannsition);

}
span.closeMobileMenu{
    position: absolute;
    left: -41px;
    background: #fff;
    padding: 1rem;
    box-shadow: 1px 3px 5px #ddd;
    z-index: 11;
    top: 57px;
} */
.header-section {
    background: #fff;
    transition: all 0.3s ease-in-out;
}
.is-sticky{
    position: -webkit-sticky;
	position: sticky;
    top: 0;
    z-index: 2;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 11px #d7d7d7;
    animation: smoothScroll 0.5s forwards;
}
@keyframes smoothScroll {
    0% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}
@media(min-width:1201px) and (max-width:1319px){
    .menubody .nav-item a {
        padding: .5rem .4rem;
        font-size: .95rem;}
}
@media(min-width:1025px) and (max-width:1199px){
    .navbar-nav a{
        padding: 0.25rem 0.25rem;
        font-size: 0.85rem;
    }
    .navbar-brand img{
        width:158px;
    }
    .menubody .nav-item a {
        padding: .5rem 0.7rem;
        font-size: 0.9rem;
}
    .menubody .nav-item a.demat-acc{
        font-size: 0.8rem;
    }
    .margin_bott-2{
        margin-bottom: .2rem;
    }

}
@media(min-width:992px) and (max-width:1024px){
    .navbar-brand img{
        width: 160px;
    }
    .navbar-nav a{
        font-size: 0.9rem;
    }

    .menubody .nav-item a {
        padding: .5rem 0.7rem;
        font-size: 0.9rem;
    }
    .menubody .nav-item a.demat-acc{
        font-size: 0.8rem;
    }
    .margin_bott-2{
        margin-bottom: .2rem;
    }

}

@media (min-width: 320px) and (max-width: 575px){
.navbar-brand img {
  width: 180px;
}
}
@media (min-width: 768px) and (max-width: 991.50px){
.menubody .nav-item a {
    padding: .5rem .3rem; }
    .list-link-footer ul{
        width: 50%;
    }
}
@media (min-width: 300px) and (max-width: 991.50px){
/* .DesktopMenuHide{display: none;} */
.MobileMenuShow{display: block;}
.DesktopMenuHide{padding-left: 0 !important; padding-right: 0 !important;}
.DesktopMenuHide .menubody div {display: none;}
.DesktopMenuHide .menubody div:last-child {display: block;}
.menubody .nav-item a {
    padding: .3rem .0rem;}
.aboutseconetoppadd {
        margin: 0.5rem 0 3rem;
    }
}
@media (min-width: 360px) and (max-width: 479.50px){
.menubody .nav-item a button{padding: .2rem .5rem .3rem;}
}
@media (min-width: 320px) and (max-width: 359.50px){
    .menubody .nav-item a button{padding: .2rem .2rem .3rem; font-size: .8rem;}
    
    }
@media (min-width:576px) and (max-width:767.90px){
    .list-link-footer ul{
        width: 50%;
        margin-bottom: 0;
    }
}
@media (min-width:481px) and (max-width:575.90px){
    .list-link-footer ul{
        width:50%;
        margin-bottom: 0;
    }
    .list-link-footer ul li{
        width: 100%;
    }
}
@media (min-width:320px) and (max-width:480.90px){
    .list-link-footer ul{
        width:50%;
        margin-bottom: 0;
    }
    .list-link-footer ul li{
        width: 100%;
    }
}