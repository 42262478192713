section.welcome{
    padding: 8rem 0;
    background-image: url(../../../Assests/images/banner-img.png),linear-gradient(111.82deg, #FBFBFB -8.69%, #E1F6FF 94.64%);
    background-position: right;
    background-repeat: no-repeat;
}
.intro-alpha h1{
    font-size: 2.75rem;
    color: var(--color-red);
    text-align: left;
    margin-bottom: 1.25rem;
}
.intro-alpha p{
    text-align: left;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0;
    color: var(--color-silver);
}
.intro-alpha p>a{
    color: var(--background-color);
    font-weight: 600;
}
.intro-alpha a{
    display: flex;
    margin-top: 1.5rem;
    background: var(--background-color);
    border:1px solid var(--background-color);
    padding: 0.5rem 1.25rem;
    border-radius: 2rem;
    width: fit-content;
    color: #fff;
}
.intro-alpha a:hover{
    background: var(--color-red);
    color: var(--color-white);
    border:1px solid var(--color-red);
}
@media(min-width:1025px) and (max-width:1199px){
    section.welcome{
        padding: 4rem 0;
    }
    .intro-alpha h1{
        font-size: 2.25rem;
    }
    .intro-alpha p{
        font-size: 0.95rem;
    }
}
@media(min-width:992px) and (max-width:1024px){
    section.welcome{
        padding: 4rem 0;
    }
    .intro-alpha h1{
        font-size: 2.25rem;
    }
    .intro-alpha p{
        font-size: 0.95rem;
    }
}
@media(min-width:768px) and (max-width:991px){
    section.welcome{
        padding: 4rem 0;
    }
    .intro-alpha h1{
        font-size: 2.25rem;
    }
    .intro-alpha p{
        font-size: 0.95rem;
    }
}
@media(min-width:576px) and (max-width:767px){
    section.welcome{
        padding: 4rem 0;
    }
    .intro-alpha h1{
        font-size: 2.25rem;
    }
    .intro-alpha p{
        font-size: 0.95rem;
    }
}
@media(min-width:320px) and (max-width:575px){
    section.welcome{
        padding: 2rem 0;
    }
    .intro-alpha h1{
        font-size: 1.75rem;
    }
    .intro-alpha p{
        font-size: 0.9rem;
    }
    .navbar-brand img{
        width: 180px;
    }
}