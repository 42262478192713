.client-reg-sc{
    background-image:linear-gradient(111.82deg, #FBFBFB -8.69%, #E1F6FF 94.64%);
    padding: 2rem 0;
}
.client-reg-sc .why-us h2{
    text-align: left;
}
.client-reg-sc .why-us h2 a {
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
    color: #000;
    text-decoration: none;
}
.client-sc p{
    text-align: left;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0;
    color: var(--color-silver);
}
.client-box-inner{
    width: 20%;
    float: left;
    box-shadow: 0px 0px 10px #f0f0f0;
    background-color: #fff;
    transition: all 0.5s ease-in-out;
}
.client-box-inner a{
    text-align: center;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0;
    color: var(--color-silver);
    padding: 1rem;
    width: 100%;
    display: block;
}
.client-box-inner a:hover{
    color: var(--color-silver);
    text-decoration: none;
}
.client-box-inner:hover{
    background-color: #e2f6ff;
    box-shadow: none;
}
.client-box-inner:hover a{
   color: #000;
} 
.client-box-main {
    float: left;
    width: 100%;
    margin: 2rem 0;
}
.client-box-inner a svg {
    margin-right: 5px;
}
.equity-client-forms{
    margin-bottom: 25px;
}
@media(min-width:481px) and (max-width:767.90px){
    .client-reg-sc .why-us h2 a{
        font-size: 21px;
    }
    .client-box-inner{
        width: 33.33%;
    }
    .client-sc p{
        font-size: 0.9rem;
    }
    .client-box-inner a{
        font-size: 0.9rem;
    }
}

@media(min-width:320px) and (max-width:480.90px){
    .client-reg-sc .why-us h2 a{
        font-size: 19px;
    }
    .client-box-inner{
        width: 49.5%;
    }
    .client-sc p{
        font-size: 0.8rem;
    }
    .client-box-inner a{
        font-size: 0.8rem;
    }
}