.attention-investor h5{
    display: flex;
    width: 100%;
    background: #F3F3F3;
    align-items: baseline;
    font-weight: normal;
    font-size: 1rem;
}
.attention-investor h5>strong{
    background: var(--background-color);
    color: var(--color-white);
    padding: 0.8rem 1rem;
    font-size: 1rem;
    width: 212px;
    display: inline-block;
    border-radius: 1.25rem;
    font-weight: 600;
}
.footer-reach-list ul{
    padding: 0;
    list-style-type: none;
    display: flex;
    text-align: left;
    flex-wrap: nowrap;
}
.footer-reach-list ul li{
    margin-right: 3rem;
}
.footer-reach-list ul li:nth-last-of-type(1){
    margin-right: 0;
}
.footer-reach-list ul li p{
    margin-bottom: 0.5rem;
    color: #4F4F4F;
    font-size: 1rem;
}
.footer-reach-list ul li h6{
    color: var(--color-black);
    font-size: 1rem;
}
.attention-investor{
    margin-bottom: 2.5rem;
}
.register-number{
    text-align: left;
}
.register-number h4{
    font-size: 1.1rem;
    font-weight: 700;
    color: var(--color-black);
}
.register-number p{
    color:#666666;
    font-size: 0.9rem;
}
.footer-logo{
    margin-left: 0;
    text-align: left;
}
.address h4{
    text-align: left;
    font-size: 1rem;
    color: #4F4F4F;
    font-weight: 400;
}
.address p{
    text-align: left;
    font-size: 1rem;
}
.list-link-footer ul{
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap
}
.list-link-footer ul li{
    width: 25%;
    text-align: left;
}
.list-link-footer ul li a{
    font-size: 0.85rem;
    color: var(--color-black);
    cursor: pointer;
    margin-bottom: 7px;
    display: inline-block !important;
    width: auto !important;
}
.complaints-block{
    text-align: left;
    display: block;
    width: 100%;
    margin-top: 1.75rem;
    margin-bottom: 2rem;
}
.complaints-block p{
    color: #666666;
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
}
.complaints-block p strong{
    color: #000;
    margin-right: 1rem;
}
.sebi-reg p{
    color: #666666;
    font-size: 0.9rem;
    margin-bottom: 0.36rem;
}
.copyright-block{
    background: var(--background-color);
    padding: 0.95rem;
    margin-top: 1rem;
}
.copyright-block p{
    margin-bottom: 0;
    color: var(--color-white);
    font-weight: 600;
    font-size: 0.85rem;
}
.copyright-block p>a{
    color: var(--color-white);
}
.complaint-modal h3{
    font-size: 1.5rem;
    color: var(--background-color);
    font-weight: bold;
    margin-bottom: 1rem;
}
.complaint-modal .modal-content{
    border:none
}
.complaint-modal .modal-dialog{
    max-width: 550px;
}
.complaint-modal svg {
    cursor: pointer;
    font-size: 24px;
    color: var(--background-color);
}
.complaint-modal ul li ul li {
    margin-bottom: 0;
}
.complaint-modal ul li ul {
    padding-left: 20px;
    margin-bottom: 10px;
}
.complaint-modal ul {
    list-style: none;
    padding-left: 0;
}
.complaint-modal ul li {
    font-size: 1rem;
    color: #4B4B4B;
    line-height: 1.8rem;
    text-align: left;
    margin-bottom: 10px;
}
.attention-investor marquee {
    padding-left: 10px;
}
.list-link-footer ul li a:not([href]):not([class]):hover{
    text-decoration: underline;
}
.list-link-footer ul {
    float: left;
    width: 25%;
}
.list-link-footer ul li,.list-link-footer ul li a{
    width: 100%;
    display: block;
}
@media(min-width:576px) and (max-width:767px){
    .attention-investor h5>strong{
        font-size: 0.85rem;
    }
    .complaint-modal h3{
        font-size: 1.3rem;
    }
    .complaint-modal ul li{
        font-size: 0.95rem;
    }
}
@media(min-width:320px) and (max-width:575px){
    .footer-reach-list ul{
        flex-wrap: wrap;
    }
    .register-number h4{
        font-size: 1rem;
    }
    .footer-logo img{
        width: 180px;
    }
    .attention-investor h5>strong{
        font-size: 0.8rem;
        padding: 0.55rem 0.5rem;
    }
    .attention-investor h5{
        font-size: 0.85rem;
    }
    .list-link-footer ul li a{
        font-size: 0.8rem;
    }
    .footer-reach-list ul li h6{
        font-size: 0.9rem;
    }
    .complaint-modal h3{
        font-size: 1.1rem;
    }
    .complaint-modal ul li{
        font-size: 0.9rem;
    }
    .list-link-footer ul li{
        width: 50%;
    }
}