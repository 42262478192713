.aboutThreesec{margin: 1rem 0; padding-top: 2rem; padding-bottom: 2rem;}
.aboutthree{background-image: url(./../../../Assests/images/aboutthreebg.png); background-repeat: no-repeat;
height: 18.1rem; background-position: top, right;}
.aboutthree ul{display: inline;}
.aboutthree ul li {list-style-type: none; display: block; margin: 0 auto; font-size: 1rem; color: #000; display: flex;
padding: .3rem 4.6rem .3rem 1rem; width: 100%; text-align: left;}
.aboutthree ul li:first-child{padding-top: 2rem;}
.aboutthree ul li:last-child{padding-bottom: 2rem; font-size: 1.1rem; line-height: 1.3rem; font-weight: 600;}

@media (max-width:1199.50px){
    .aboutthree ul li{
        padding: .3rem 3.6rem .3rem 1rem;
    }
    .aboutthree ul li:last-child {
        font-size: 1rem;}
}
@media (max-width:991.50px){
    .aboutthree ul li{
        padding: .3rem 2.6rem .3rem 1rem;
    }
    .aboutthree ul li:last-child {
        font-size: .8rem;}
   .aboutthree{background-size: 100%;}
}
@media (max-width:767.50px){
    .aboutthree ul li:last-child {
       line-height: 1.2rem;
      }

}

@media (max-width: 359.50px){
    .aboutthree ul li{
        font-size: 0.8rem;
    }
    .aboutthree ul li:first-child {
        padding-top: 1rem;
      }
      .aboutthree{ height: 14.1rem;   }
      .aboutthree ul li {
        padding: .3rem 2.6rem .3rem .4rem;}
        .aboutThreesec {
            margin: .3rem 0;}
}