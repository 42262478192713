.product-services{
    padding: 3rem 0;
}
.product-services h1{
    color: var(--color-black);
    font-weight: bold;
    text-align: left;
    font-weight: 800;
    font-size: 2.5rem;
}
.product-services h5{
    color: #888;
    font-size: 1.2rem;
    text-align: left;
    line-height: 1.8rem;
    font-weight: 400;
}
.each-product-service{
    margin-bottom: 1rem;
}
.each-product-service h4{
    color: var(--background-color);
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 700;
    min-height: 50px;
}
.each-product-service p{
    font-size: 0.9rem;
    color: #4B4B4B;
    line-height: 1.2rem;
    text-align: center;
}
.each-product-service span{
    transform: rotate(-90deg);
    display: inline-block;
    font-size: 2rem;
    color: #D6D6D6;
    transition: all 0.3s ease-in-out;
    font-weight: bold;
}
.each-product-service{
    padding: 1.5rem;
    transition: all 0.3s ease-in-out;
}
.each-product-service:hover{
    background: #FEEEEF;
    border-radius: 10px;
    box-shadow: 2px 2px 10px #ddd;
}
.each-product-service img{
    margin-top: 1rem;
    margin-bottom: 1rem;
    transition: all 0.3s ease-in-out;
}
.each-product-service:hover img{
    transform: scale(0.9);
}
.each-product-service:hover span{
    color: var(--background-color);
}
@media(min-width:1025px) and (max-width:1199px){
    .product-services h1{
        font-size: 2rem;
    }
    .product-services h5{
        font-size: 1.1rem;
    }
}
@media(min-width:992px) and (max-width:1024px){
    .product-services h1{
        font-size: 2rem;
    }
    .product-services h5{
        font-size: 1.1rem;
    }
}
@media(min-width:768px) and (max-width:991px){
    .product-services h1{
        font-size: 1.65rem;
    }
    .product-services h5{
        font-size: 1rem;
    }
    .product-services h5>br{
        display: none;
    }
}
@media(min-width:576px) and (max-width:767px){
    .about{
        padding: 3rem 0;
    }
    .product-services h1{
        font-size: 1.65rem;
    }
    .product-services h5{
        font-size: 1.1rem;
        margin-bottom: 2rem;
    }
    .each-product-service {
        padding: 1.5rem;
        transition: all 0.3s ease-in-out;
        width: 100%;
        margin: 0 auto 1rem;
    }
   
}
@media(min-width:320px) and (max-width:575px){
    .product-services h1{
        font-size: 1.35rem;
    }
    .product-services h5{
        font-size: 1.1rem;
        line-height: 1.4rem;
        margin-bottom: 2rem;
    }
    .copyright-block p{
        font-size: 0.8rem;
    }
    .why-us h2>span{
        font-size: 0.9rem;
    }
    .why-us h2{
        font-size: 1.5rem;
    }
    .why-us p{
        line-height: 1.5rem;
    }
}
