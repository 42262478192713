.abtsecforubg{background: linear-gradient(to bottom, #F7F8FA 0%, #E2F1F9 100%); margin: 2.5rem 0;}
.vmtext{display: block;}
.vmtext ul{display: block; padding: 3rem;}
.vmtext ul li {display: block; color: #000; font-size: 1rem; text-align: left; padding: .3rem 1rem;}
.vmtext ul li:first-child {font-size: 2.6rem; color: #211F72; font-weight: 800;}
@media (max-width: 991.50px){
.vmtext ul li:first-child{font-size: 2rem;}
}
@media (max-width : 767.50px){
    .vmtext ul{padding: 0;}
    .vmtext ul li{text-align: center;}
}
@media (max-width: 359.5px){
.vmtext ul li:first-child {
  line-height: 2rem;
}
.vmtext ul li{padding: .3rem 0rem;}
.abtsecforubg{
    margin: .5rem 0;
}
}