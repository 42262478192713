.history{
    padding: 5rem 0 3rem 0;
}
.history-beta{
    background: var(--background-color);
    color: var(--color-white);
    padding: 3rem 4rem 3rem 7rem;
    text-align: left;
    margin-top: 1.5rem;
}
.history-alpha img{
    transform: scale(1.15);
    margin-left: 5rem;
    margin-top: 0rem;
    z-index: 1;
    position: relative;
}
.history h2{
    font-weight: bold;
    font-size: 2.1rem;
    margin-bottom: 1.75rem;
}
.history p{
    font-size: 0.9rem;
    color: var(--color-white);
    line-height: 1.9rem;
}
@media(min-width:768px) and (max-width:991px){
    .history h2{
        font-size: 1.65rem;
    }
    .history-beta{
        padding: 3rem 3rem 3rem 5rem;
    }
    .about{
        padding: 3rem 0;
    }
}
@media(min-width:576px) and (max-width:767px){
    .history-alpha img{
        margin-left: 0;
        transform:scale(1);
    }
    .history-beta{
        padding: 2rem;
    }
    .history h2{
        font-size: 1.65rem;
    }
    .history{
        padding: 2rem 0 3rem;
    }
    
}

@media(min-width:320px) and (max-width:575px){
    .history-alpha img{
        margin-left: 0;
        transform:scale(1);
    }
    .history-beta{
        padding: 1rem;
    }
    .history h2{
        font-size: 1.25rem;
        margin-bottom: 1rem;
    }
    .history p{
        line-height: 1.5rem;
    }
    button.read-more{
        box-shadow: none;
        padding: 0.35rem 1.75rem;
        margin-top: 0.5rem;
    }
    .history{
        padding: 1rem 0 2rem;
    }
    
}