section.why-us{
    padding: 4rem 0;
    background: #FFFCDA;
    text-align: left;
}
.why-us h2{
    font-size: 2.5rem;
    color: var(--color-black);
    font-weight: bold;
    margin-bottom: 1.5rem;
}
.why-us h2>span{
    display: block;
    font-size: 1.13rem;
    color: var(--color-red);
}
.why-us p{
    line-height: 1.65rem;
    font-size: 0.9rem;
}
.read-more{
    background-color: var(--color-white);
  padding: 0.65rem 2.75rem;
  border-radius: 1.5rem;
  font-weight: bold;
  color: var(--background-color);
  margin-top: 1rem;
  display: inline-block;
  border-color: var(--color-white);
  font-size: 1rem;
}
.read-more:hover{
    background-color: #0069d9;
    border-color: #0062cc;
    color: #fff;
    text-decoration: none;
}
@media(min-width:1025px) and (max-width:1199px){
    .why-us h2{
        font-size: 2rem;
    }
}
@media(min-width:992px) and (max-width:1024px){
    .why-us h2{
        font-size: 2rem;
    }
}
@media(min-width:768px) and (max-width:991px){
    .why-us h2{
        font-size: 1.65rem;
    }
}
@media(min-width:576px) and (max-width:767px){
    .why-us h2{
        font-size: 1.65rem;
    }
}
@media(min-width:320px) and (max-width:575px){
    section.why-us{
        padding: 2rem 0;
    }
    .why-us h2{
        font-size: 1.6rem;
    }
    .why-us h2>span{
        font-size: 0.9rem;
    }
    .why-us p{
        line-height: 1.5rem;
    }
}