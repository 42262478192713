.static-pg h3{
    color: #000;
    font-size: 20px;
    font-weight: 700;
    text-align: left;
}
@media(min-width:768px) and (max-width:991.90px){
    .static-pg h3{
        font-size: 18px;
    }
}

@media(min-width:481px) and (max-width:767.90px){
    .static-pg h3{
        font-size: 17px;
    }
}

@media(min-width:320px) and (max-width:480.90px){
    .static-pg h3{
        font-size: 16px;
    }
}