.aboutseconebg{
    background-image: url(./../../../Assests/images/Contact-hero-img.png); background-position: top, left;
    background-size: 100%; background-repeat: no-repeat;
}
.ContactCompanyName h1{color: #211F72; font-size: 2rem; padding: 2rem 1rem 1rem; font-weight: 900; text-align: left;}
.contactRegisterAddr{display: flex;}
.contactRegisterAddr ul{ display: block; padding: 0;}
.contactRegisterAddr ul li{ color: #000; font-size: 1rem; padding: .3rem 1rem; text-align: left;
     list-style-type: none; width: 100%; font-weight: 600;}
.contactRegisterAddr ul li:first-child{font-size: 1.8rem;}
.contactRegisterAddr ul li:nth-child(3),.contactRegisterAddr ul li:nth-child(4){width: 45%; float: left;}
.contactRegisterAddr ul li span{color: #a4a4a4; font-size: .8rem;}
.topheadinPass{padding-top: 2rem;}
.about-hero-imgBlankPass{height: 10rem;}
.ContactLeadfrom{ margin-top: -6rem; background: #fff; border-radius: 1rem; box-shadow: 0px 10px 9px #f0f0f0;
    margin-bottom: 2rem;}
.ContactLeadformHeading{font-size: 2.3rem; color: #211F72; text-align: left; padding: 1rem; 
    display: inherit; font-weight: 700;}
.ContactLeadformHeading span{font-size: 1.1rem; color: #a4a4a4; font-weight: 400;}
.Cont-lead-aliment{display: flex;}
.Cont-lead-aliment input{margin-right: .3rem;}
.SednButton {background: #211F72 !important; color: #fff !important; float: left;
padding: .4rem 2rem !important; border: 0 !important; border-radius: 2rem !important; margin-bottom: 1rem;}

@media (min-width: 992px) and (max-width: 1199.50px){
    .about-hero-imgBlankPass {
        height: 6rem;
      }
      .ContactCompanyName h1 {padding: 2rem 1rem 0rem;}
      .contactRegisterAddr ul li{padding: .2rem 1rem;}
}
@media (min-width: 768px) and (max-width: 991.50px){
    .ContactCompanyName h1 {
        font-size: 1.8rem;  padding: .6rem 1rem 0rem;  }
      .contactRegisterAddr ul li {
        font-size: .8rem; padding: .1rem 1rem;}
      .contactRegisterAddr ul li:first-child {
            font-size: 1.4rem;   }
     .about-hero-imgBlankPass { height: 0rem;  }
     .ContactLeadfrom {margin-top: 0rem;}
     .ContactLeadformHeading {font-size: 1.8rem;}
     .ContactLeadformHeading span { font-size: 1rem; display: block;  }
}

@media (min-width: 576px) and (max-width: 767.50px){
    .ContactCompanyName h1 {
        font-size: 1.5rem;  padding: .4rem 1rem 0rem; margin: 0;  }
      .contactRegisterAddr ul li {
        font-size: .8rem; padding: .0rem 1rem; margin: 0;}
      .contactRegisterAddr ul li:first-child {
            font-size: 1.4rem;   }
     .about-hero-imgBlankPass { height: 0rem;  }
     .ContactLeadfrom {margin-top: 0rem;}
     .ContactLeadformHeading {font-size: 1.4rem;}
     .ContactLeadformHeading span { font-size: .9rem; display: block;  }
     .Cont-lead-aliment input{ font-size: .8rem;}

}

@media (min-width: 480px) and (max-width: 575.50px){
    .ContactCompanyName h1 {
        font-size: 1.3rem;  padding: .4rem 0rem 0rem; margin: 0;  }
      .contactRegisterAddr ul li {
        font-size: .8rem; padding: .0rem 0rem; margin: 0;}
      .contactRegisterAddr ul li:first-child {font-size: 1.1rem;}
     .about-hero-imgBlankPass { height: 0rem;  }
     .ContactLeadfrom {margin-top: 0rem;}
     .ContactLeadformHeading {font-size: 1.4rem;}
     .ContactLeadformHeading span { font-size: .9rem; display: block;  }
     .aboutseconebg{background-size: 126%;}
     .Cont-lead-aliment input{ font-size: .8rem;}

}

@media (max-width: 497.50px){
    .ContactCompanyName h1 {
        font-size: 1.3rem;  padding: .4rem 0rem 0rem; margin: 0;  }
      .contactRegisterAddr ul li {
        font-size: .8rem; padding: .0rem 0rem; margin: 0;}
      .contactRegisterAddr ul li:first-child {font-size: 1.1rem;}
     .about-hero-imgBlankPass { height: 0rem;  }
     .ContactLeadfrom {margin-top: 0rem;}
     .ContactLeadformHeading {font-size: 1.4rem;}
     .ContactLeadformHeading span { font-size: .9rem; display: block;  }
     .aboutseconebg{background-size: 186%;}
     .Cont-lead-aliment input{ font-size: .8rem;}

}

@media (max-width: 359.50px){ 
    .aboutseconebg{background-size: 231%;}
}
@media (max-width: 575.50px){ 
    .leftrifgrpadd0{ padding-left: 0 !important; padding-right: 0 !important;}
}